import React, { Component } from 'react'
import axios from "axios"
import store from "store"
import Layout from "../../../Components/reusable/Layout"
import Banner from "../../../Components/reusable/Banner"
import img from "../../../Images/products/agri.jpg"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CategoryDetail from '../../../Components/category/CategoryDetail'
import { Link, navigate } from 'gatsby'
import { BrandContext } from '../../../Components/context/themeContext'




export class Automotive extends Component {
    state={
        categories:[],
        value:1,
        products:[],
        brands:[]
    }

    static contextType = BrandContext;
    

    FetchCategory = ()=>{
    axios.post("https://www.iamrachit.in/klsons/appapi/categories",
    JSON.stringify({
    "service_request": {
        "params": {
            "cat_type": "sprockets"
        },
        "request_info": {
            "source_type": "android"
        }
    },
    "version": "1.0"

     }),{
 headers: {
     'Content-Type': 'application/json'
   }
})
.then(res=>{
    console.log("categories=>",res);
    this.setState({
        ...this.state,
        categories:res.data.categories[4]
    })
    })
}

FetchBrands = () =>{
    axios.post("https://www.iamrachit.in/klsons/appapi/brands",
    JSON.stringify({
    "service_request": {
        "params": {
            "cat_id": "16"
        },
        "request_info": {
            "source_type": "android"
        }
    },
    "version": "1.0"
}),{
 headers: {
     'Content-Type': 'application/json'
   }
})
.then(res=>{
    console.log("brands=>",res.data);
    this.setState({
        ...this.state,
        brands:res.data.brands
    })
    })
}


    componentDidMount(){
        this.FetchCategory()
        this.FetchBrands()
    }

    inc = ()=>{
        this.setState({
            ...this.state,
            value:this.state.value+1
        })
    }
    dec = ()=>{
        if (this.state.value>1) {
            this.setState({
            ...this.state,
            value:this.state.value-1
        })
        }
    }

    handleChange =(e)=>{
       
        this.setState({
            ...this.state,
            value:Number(e.target.value)
        })
           
        
    }


    handleTransfer=(brand,id)=>{
        store.set("brand",{brand,id})
      this.context.setbrand(brand)
        navigate("/brands",{state:{brand}})
    }

    

    AddToEnq = (value=1,message="") =>{

        toast.error(`${this.state.categories.category_title} added for enquiry`,{autoClose: 1000,})
        let newProduct = []

        const myProduct = {
            name:this.state.categories.category_title,
            quantity:value,
            message
        }
        
        if (store.get("products")) {
            newProduct= store.get("products")
            newProduct.push(myProduct)
            store.set("products",newProduct)
            this.setState({
            ...this.state,
            products:newProduct
        })
           
        }else{
            newProduct.push(myProduct)
            store.set("products",newProduct)
             this.setState({
            ...this.state,
            products:newProduct
        })
        }

    }





    render() {
            const {category_title,short_desc,full_desc,category_photo} = this.state.categories
        return (
            <>
             <CategoryDetail title={category_title} img={category_photo} handleChange={this.handleChange} short_desc={short_desc} full_desc={full_desc} AddToEnq={this.AddToEnq} inc={this.inc} dec={this.dec} >
            
            <section className="container">
                <div className="row">
                    <div className="col-12">
                        <h3>Explore Products</h3>
                    </div>

                </div>
                
                <div className="row">
                    {
                        this.state.brands.map(({brand_title,brand_id,brand_img})=>{
                            return (
                            <>
                             <div className="col-md-12">
                                <Link to={"/allbrands/"+brand_id} key={brand_id}>
                                    <div
                                    // onClick={() =>
                                    //   this.handleTransfer(brand_title, brand_id)
                                    // }
                                    className="col-md-12"
                                    key={brand_id}
                                >
                                        {console.log(brand_title)}
                                        <p style={{ cursor: "pointer" }}>
                                        {brand_title}
                                        </p>
                                        {/* <Link to="/brands">
                                        <img src={`https://www.iamrachit.in/klsons/uploads/${brand_img}`} style={{width:"100%"}}/>
                                        </Link> */}
                                </div>
                             </Link>
                             </div>
                            </>
                            )
                        })
                    }
                </div>

            </section>
            
            </CategoryDetail>
         </>   
        )
    }
}

export default Automotive
